import { Box, Typography } from '@mui/material';

import React from 'react';

import { useNavigate } from 'react-router-dom';
import { BranchCard } from '../BranchCard';
import routesConstants from 'utils/routesConstants';
import { BranchWithTodayScheduleUI } from 'types/Branch';
import { Featured } from 'types/Responses/GetHomepageResponse';

type BranchListProps = {
  branches: BranchWithTodayScheduleUI[];
  featured: Featured[];
};

export const BranchList: React.FC<BranchListProps> = ({
  branches,
  featured,
}) => {
  const navigate = useNavigate();

  const OpenShop = (branchId: number) => {
    navigate(routesConstants.branch(branchId.toString()));
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      sx={{
        maxWidth: { xs: '100vw', sm: '100vw', md: '100%' },
        bgcolor: 'background.paper',
      }}
    >
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {featured.map(
          (feature) =>
            feature.data.length > 0 && (
              <Box key={feature.name}>
                <Typography
                  sx={{
                    margin: 2,
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {feature.name}
                </Typography>
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'flex', md: 'flex' },
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {feature.data.map((branch) => (
                    <BranchCard
                      key={branch.id}
                      branch={branch}
                      OpenShop={OpenShop}
                    />
                  ))}
                </Box>
              </Box>
            ),
        )}
        <Typography
          sx={{
            margin: 2,
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Restaurantes
        </Typography>
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex', md: 'flex' },
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {branches.map((branch) => (
            <BranchCard key={branch.id} branch={branch} OpenShop={OpenShop} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
