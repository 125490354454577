import * as React from 'react';
import { BottomBar } from './BottomBar';
import { TopBar } from './TopBar';
import {
  HomeOutlined,
  PersonOutline,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import routesConstants from 'utils/routesConstants';

const pages = [
  {
    name: 'Inicio',
    route: routesConstants.home,
    icon: <HomeOutlined />,
  },

  {
    name: 'Ordenes',
    route: routesConstants.orders,
    icon: <ReceiptLongOutlined />,
  },
  {
    name: 'Perfil',
    route: routesConstants.profile,
    icon: <PersonOutline />,
  },
];

type AppbarProps = {
  children: React.ReactNode;
  hideComponents: any;
};

export const Appbar: React.FC<AppbarProps> = ({ children, hideComponents }) => {
  return (
    <>
      {!hideComponents && <TopBar pages={pages} />}
      {children}
      {!hideComponents && <BottomBar pages={pages} />}
    </>
  );
};
