import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import { Order } from 'types/Order';
import {
  formatDateWithTime,
  formatTimeString,
  toCurrencyFormat,
} from 'utils/formatter';
import { OrderProcessStepper } from '../OrderProcessStepper';
import chaletApi from 'api/chaletApi';

const CustomCard = styled(Card)(({ theme }) => ({
  margin: 5,
  borderRadius: '7px',
  cursor: 'pointer',
}));

type OrderCardProps = {
  order: Order;
  handleOpen?: () => void;
};

const getLastOrderStatusTime = (order: Order): string => {
  const { orderStatusTimes, createdAt } = order;
  const statusTimes = [
    orderStatusTimes.deliveredAt,
    orderStatusTimes.deliveringAt,
    orderStatusTimes.readyAt,
    orderStatusTimes.preparingAt,
    orderStatusTimes.confirmedAt,
  ].filter(Boolean);

  return statusTimes.length > 0 ? statusTimes[0] : createdAt;
};

export const OrderCard: React.FC<OrderCardProps> = ({ order, handleOpen }) => {
  const { data: currentOrderProcessStep } =
    chaletApi.useGetCurrentOrderProcessStepQuery(
      order.currentOrderProcessStepId,
    );

  const groupedVariations: Record<
    number,
    Record<string, { value: string; addedValue: number }[]>
  > = {};

  order.details.forEach((orderDetail) => {
    if (!groupedVariations[orderDetail.id]) {
      groupedVariations[orderDetail.id] = {};
    }

    orderDetail.variations?.forEach((variation) => {
      const variationName = variation.option.variation.name;

      if (!groupedVariations[orderDetail.id][variationName]) {
        groupedVariations[orderDetail.id][variationName] = [];
      }

      groupedVariations[orderDetail.id][variationName].push(variation.option);
    });
  });

  const lastOrderStatusTime = getLastOrderStatusTime(order);

  return (
    <CustomCard elevation={4} onClick={handleOpen}>
      <CardContent style={{ padding: '10px', position: 'relative' }}>
        <Box>
          <Typography flex={1} variant="body2">
            {formatDateWithTime(order.createdAt)}
          </Typography>
          <Box
            id="orderid"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: '#EF3D32',
              borderRadius: '0px  7px',
            }}
          >
            <Typography color={'#FFFFFF'} variant="body2">
              {order.id}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          {order.details.map((orderDetail) => (
            <Box display={'flex'} key={orderDetail.id}>
              <Box flex={1}>
                <Typography
                  sx={{ fontSize: 14 }}
                  fontWeight={'bold'}
                  gutterBottom
                >
                  {orderDetail.name} ({toCurrencyFormat(orderDetail.price)}) x{' '}
                  {orderDetail.quantity}
                </Typography>

                {orderDetail.variations &&
                  orderDetail.variations.length > 0 && (
                    <div>
                      {Object.entries(groupedVariations[orderDetail.id]).map(
                        ([variationName, options]) => (
                          <Typography
                            key={variationName}
                            sx={{ fontSize: 12 }}
                            color="text.secondary"
                          >
                            {variationName}:{' '}
                            {options
                              .map(
                                (option, index) =>
                                  `${option.value} ($${option.addedValue})`,
                              )
                              .join(', ')}
                          </Typography>
                        ),
                      )}
                    </div>
                  )}
                <Typography
                  sx={{ fontSize: 12 }}
                  color={'text.secondary'}
                  gutterBottom
                >
                  Notas:{' '}
                  {orderDetail.notes
                    ? orderDetail.notes
                    : 'Sin notas adicionales'}
                </Typography>
              </Box>
              <Box flex={0}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {toCurrencyFormat(orderDetail.price * orderDetail.quantity)}
                </Typography>
              </Box>
            </Box>
          ))}

          <Box display={'flex'}>
            <Typography flex={1} fontWeight={'bold'}>
              Delivery:{' '}
            </Typography>
            <Typography flex={0}>
              {toCurrencyFormat(order.deliveryPrice)}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography flex={1} fontWeight={'bold'}>
              Total:{' '}
            </Typography>
            <Typography flex={0}>{toCurrencyFormat(order.total)}</Typography>
          </Box>
        </Box>
        <Box>
          {order.isActive && currentOrderProcessStep && (
            <OrderProcessStepper
              steps={6}
              currentStep={currentOrderProcessStep.correlative}
              stepName={currentOrderProcessStep.name}
              lastUpdatedTime={lastOrderStatusTime}
              icon={currentOrderProcessStep.icon}
            />
          )}
          {order.isCompleted && (
            <Typography variant="subtitle2" color="#0e766e">
              ● Entregado{' '}
              {formatTimeString(
                order.orderStatusTimes.deliveredAt || order.createdAt,
              )}
            </Typography>
          )}
          {order.isCanceled && (
            <Typography variant="subtitle2" color="#C0392B">
              ● Cancelado {formatTimeString(lastOrderStatusTime)}
            </Typography>
          )}
        </Box>
      </CardContent>
    </CustomCard>
  );
};
