import React, { useState } from "react";
import chaletApi from "api/chaletApi";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { OrderDetailStep } from "./OrderDetailStep";

interface VariationBodyState {
  id: number;
  addedValue: number;
  value: string;
  variationId: number;
}

type InitialProductVariationProps = {
  productId: number;
  handleNextStep: (variation: any, quantity: number, notes: string) => void;
  handleBackStep: () => void;
};

export const InitialProductVariation: React.FC<
  InitialProductVariationProps
> = ({ productId, handleNextStep, handleBackStep }) => {
  const [variationBody, setVariationBody] = useState<VariationBodyState[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [notes, setNotes] = useState<string>("");

  const { data } = chaletApi.useGetProductByIdQuery(productId);

  const handleInitial = (
    initialRadioState: Record<string, VariationBodyState>,
  ) => {
    setVariationBody(Object.values(initialRadioState));
  };

  const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const HandleVariationsBodyChange = (data: {
    variationId: number;
    variationOptionId: number;
    value: string;
    addedValue: number;
    type: string;
  }) => {
    const { variationId, variationOptionId: id, type } = data;

    setVariationBody((prevState) => {
      let updatedVariationBody = [...prevState];

      if (type === "radio") {
        updatedVariationBody = updatedVariationBody.filter(
          (item) => item.variationId !== variationId,
        );
      }

      const existingIndex = updatedVariationBody.findIndex(
        (item) => item.id === id,
      );

      if (existingIndex !== -1) {
        updatedVariationBody.splice(existingIndex, 1);
      } else {
        updatedVariationBody.push({
          variationId,
          id,
          value: data.value,
          addedValue: data.addedValue,
        });
      }

      return updatedVariationBody;
    });
  };

  return (
    <Box>
      <OrderDetailStep
        variations={data?.variations || []}
        initial={handleInitial}
        onChange={HandleVariationsBodyChange}
      />
      <Box flex={1} mt={1} mb={1}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography ml={2} mr={2} fontWeight={"bold"}>
            Cantidad
          </Typography>
          <Button variant="contained" size="small" onClick={decreaseQuantity}>
            <Remove />
          </Button>
          <Typography ml={2} mr={2}>
            {quantity}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={increaseQuantity}
          >
            <Add />
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginBottom: { xs: "100px", md: "0px" } }}>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Cambios adicionales"
          name="notes"
          multiline
          rows={3}
          helperText="Por ejemplo: Sin lechuga"
          onChange={handleChangeNotes}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
          mb: 2,
        }}
      >
        <Button variant="outlined" onClick={() => handleBackStep()}>
          Atrás
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNextStep(variationBody, quantity, notes)}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};
