import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { toCurrencyFormat } from '../../../../../utils/formatter';
import { Add, Remove } from '@mui/icons-material';
import { ProductVariationView } from '../ProductVariationView';
import { CustomBox } from '../style';
import { UserCart } from 'types/UserCart';
import chaletApi from 'api/chaletApi';
import { grey } from '@mui/material/colors';

type HorizontalProductCardProps = {
  cart: UserCart;
};

export const HorizontalProductCard: React.FC<HorizontalProductCardProps> = ({
  cart,
}) => {
  const [deleteCartItem, { isLoading: isDeleting }] =
    chaletApi.useDeleteCartItemMutation();

  const [incrementCartItem, { isLoading: isIncrement }] =
    chaletApi.useIncrementCartItemMutation();

  const [decrementCartItem, { isLoading: isDecrement }] =
    chaletApi.useDecrementCartItemMutation();

  const handleDecrement = (itemId: number, quantity: number) => {
    if (quantity <= 1) {
      deleteCartItem({ id: itemId });
    } else {
      decrementCartItem({ id: itemId });
    }
  };
  const handleIncrement = (itemId: number) => {
    incrementCartItem({ id: itemId });
  };

  return cart && cart.items.length > 0 ? (
    cart.items.map((item) => {
      return (
        <Box key={item.id}>
          <CustomBox>
            <Box display={'flex'}>
              {item.product.image && (
                <Box mr={1}>
                  <img
                    width={100}
                    height={100}
                    src={item.product.image}
                    style={{
                      borderRadius: '5px',
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}

              <Box display={'flex'} flexDirection={'column'} flex={1}>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                  <Typography fontSize={16}>{item.name}</Typography>
                  <ProductVariationView product={item} />
                  {item.notes !== '' && (
                    <Typography sx={{ fontSize: 12 }} color={'text.secondary'}>
                      {item.notes}
                    </Typography>
                  )}
                </Box>
                <Typography fontSize={15}>
                  {toCurrencyFormat(item.price * item.quantity)}
                </Typography>
              </Box>

              <Stack
                direction={'row'}
                spacing={1}
                mt={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: grey[200],
                    alignItems: 'center',
                    borderRadius: 2,
                    gap: 1,
                  }}
                >
                  <IconButton
                    onClick={() => handleDecrement(item.id, item.quantity)}
                    disabled={isDecrement || isDeleting || isIncrement}
                  >
                    <Remove />
                  </IconButton>
                  {isDecrement || isDeleting || isIncrement ? (
                    <CircularProgress sx={{ maxWidth: 20, maxHeight: 20 }} />
                  ) : (
                    <Typography fontSize={18}>{item.quantity}</Typography>
                  )}

                  <IconButton
                    onClick={() => handleIncrement(item.id)}
                    disabled={isDecrement || isDeleting || isIncrement}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </CustomBox>
          <Divider sx={{ mb: 1, mt: 1 }}></Divider>
        </Box>
      );
    })
  ) : (
    <Typography variant="body1" color="textSecondary" textAlign="center">
      No se encontraron ordenes para esta tienda.
    </Typography>
  );
};

// type HorizontalProductCardProps = {
//   currentShop: ShopCartItem;
// };

// export const HorizontalProductCard: React.FC<HorizontalProductCardProps> = ({
//   currentShop,
// }) => {
//   const dispatch = useAppDispatch();

//   const handleRemoveFromCart = (uniqueProductId: number) => {
//     dispatch(
//       removeFromCart({
//         shopId: currentShop.shopId,
//         uniqueProductId: uniqueProductId,
//       }),
//     );
//   };

//   return currentShop && currentShop.products.length > 0 ? (
//     currentShop.products.map((item) => {
//       return (
//         <Box key={item.uniqueProductId}>
//           <CustomBox>
//             <Box display={'flex'}>
//               {item.image && (
//                 <Box mr={1}>
//                   <img
//                     width={100}
//                     height={100}
//                     src={item.image}
//                     style={{
//                       borderRadius: '5px',
//                       aspectRatio: '1/1',
//                       objectFit: 'cover',
//                     }}
//                   />
//                 </Box>
//               )}

//               <Box display={'flex'} flexDirection={'column'} flex={1}>
//                 <Box>
//                   <Typography fontSize={16}>
//                     {item.name} X{item.quantity}
//                   </Typography>
//                 </Box>
//                 <Box>
//                   <ProductVariationView product={item} />
//                 </Box>

//                 {item.notes !== '' && (
//                   <Box>
//                     <Typography sx={{ fontSize: 12 }} color={'text.secondary'}>
//                       {item.notes}
//                     </Typography>
//                   </Box>
//                 )}

//                 <Box id="caja" alignSelf={'self-end'}>
//                   <Stack direction={'row'} spacing={1} mt={1}>
//                     <Button
//                       variant="outlined"
//                       size="small"
//                       onClick={() => handleRemoveFromCart(item.uniqueProductId)}
//                     >
//                       <Remove />
//                     </Button>
//                     <Typography fontSize={18}>
//                       {toCurrencyFormat(item.total)}
//                     </Typography>
//                   </Stack>
//                 </Box>
//               </Box>
//             </Box>
//           </CustomBox>
//           <Divider sx={{ mb: 1, mt: 1 }}></Divider>
//         </Box>
//       );
//     })
//   ) : (
//     <Typography variant="body1" color="textSecondary" textAlign="center">
//       No se encontraron ordenes para esta tienda.
//     </Typography>
//   );
// };
