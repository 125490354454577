import { Add, Remove, ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
  Grid,
  Paper,
  Modal,
  Toolbar,
  AppBar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import chaletApi from 'api/chaletApi';
import { toCurrencyFormat } from 'utils/formatter';
import { ProductVariation } from '../../components/partials/ProductVariation';
import { toast } from 'react-hot-toast';
import {
  CustomBox,
  CustomCard,
  CustomGrid,
  CustomImage,
  CustomTypography,
} from './style';
import { useAppSelector } from 'redux/hook';

interface VariationBodyState {
  id: number;
  addedValue: number;
  value: string;
  variationId: number;
}

type ProductProps = {
  open: boolean;
  onClose: () => void;
  branchId: number;
  productId: number;
};

export const Product: React.FC<ProductProps> = ({
  open,
  onClose,
  branchId,
  productId,
}) => {
  const { data, isLoading, isError } =
    chaletApi.useGetProductByIdQuery(productId);
  const [VariationBody, setVariationBody] = React.useState<
    Record<string, VariationBodyState>
  >({});
  const [quantity, setQuantity] = useState<number>(1);
  const [notes, setNotes] = useState<string>('');

  const [currentCart, setCurrentCart] = useState<{
    branchId: number;
    id: number;
  }>();

  const { isLoggedIn, userCart } = useAppSelector(
    (state) => state.userAuthSlice,
  );
  const { deviceCart } = useAppSelector((state) => state.device);

  useEffect(() => {
    if (isLoggedIn) {
      setCurrentCart(userCart.find((cart) => cart.branchId === branchId));
    } else {
      setCurrentCart(deviceCart.find((cart) => cart.branchId === branchId));
    }
  }, [isLoggedIn, userCart, deviceCart, branchId]);

  const [createCartItem] = chaletApi.useCreateCartItemMutation();

  let product: any = null;

  if (isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }
  if (isError) {
    toast.error('Ha ocurrido un error al obtener la tienda');
  }

  if (data) {
    product = data;

    const handleInitial = (
      initialRadioState: Record<string, VariationBodyState>,
    ) => {
      setVariationBody(initialRadioState);
    };

    const HandleVariationsBodyChange = (data: {
      variationId: number;
      variationOptionId: number;
      value: string;
      addedValue: number;
      type: string;
    }) => {
      const { variationId, variationOptionId: id, type } = data;

      setVariationBody((prevState) => {
        const updatedVariationBody = { ...prevState };

        if (type === 'radio') {
          // Si es una opción de radio, eliminamos las opciones anteriores del mismo variationId
          for (const existingKey in updatedVariationBody) {
            if (updatedVariationBody[existingKey].variationId === variationId) {
              delete updatedVariationBody[existingKey];
            }
          }

          updatedVariationBody[id] = {
            variationId,
            id,
            value: data.value,
            addedValue: data.addedValue,
          };
        } else {
          // Si es una opción de checkbox, alternamos su estado
          if (updatedVariationBody[id]) {
            delete updatedVariationBody[id];
          } else {
            updatedVariationBody[id] = {
              variationId,
              id,
              value: data.value,
              addedValue: data.addedValue,
            };
          }
        }

        return updatedVariationBody;
      });
    };

    const increaseQuantity = () => {
      setQuantity(quantity + 1);
    };

    const decreaseQuantity = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotes(event.target.value);
    };

    const ProductWithAddedValue = () => {
      let totalAddedValue: number = 0;

      Object.values(VariationBody).forEach((variation) => {
        totalAddedValue += variation.addedValue;
      });

      const ProductWithAddedValue = product.price + totalAddedValue;
      //const totalPrice = quantity * ProductWithAddedValue;

      return ProductWithAddedValue;
    };

    const handleAddToCart = async () => {
      const variationsArray = Object.values(VariationBody);

      const variationsIncrease = variationsArray.map((variation) => ({
        ...variation,
        increase: variation.addedValue,
        variationOptionId: variation.id,
        addedValue: undefined,
      }));

      if (isLoggedIn) {
        const productData = {
          productId: product.id,
          quantity: quantity,
          notes: notes,
          name: product.name,
          variations: variationsIncrease,
          userCartId: currentCart?.id,
        };

        await createCartItem(productData).unwrap();
        toast.success('producto agregado');
        onClose();
      } else {
        const productData = {
          productId: product.id,
          quantity: quantity,
          notes: notes,
          name: product.name,
          variations: variationsIncrease,
          userCartId: currentCart?.id,
        };

        await createCartItem(productData).unwrap();
        toast.success('producto agregado');
        onClose();
      }
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            position: 'relative',
            overflow: 'auto',
            maxHeight: {
              xs: '100vh',
              sm: '100vh',
              md: '90vh',
            },
            width: {
              xs: '100%',
              sm: '100%',
              md: '80%',
            },
            padding: 0,
          }}
        >
          <Paper
            elevation={1}
            sx={{
              height: {
                xs: '100%',
                sm: '100%',
                md: 'auto',
              },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AppBar
              id="app-bar"
              position="sticky"
              sx={{
                transition: 'all 0.3s ease-in-out',
                top: 0,
                zIndex: 2,
                backgroundColor: '#FFFFFF',
              }}
            >
              <Container maxWidth="xl">
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }}>
                    <Button
                      color="primary"
                      aria-label="back"
                      onClick={onClose}
                      startIcon={<ArrowBack />}
                    >
                      Atrás
                    </Button>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            <CustomBox display="flex" flexDirection={'row'}>
              {product.image && (
                <Box display={'flex'}>
                  <CustomImage src={product.image} />
                </Box>
              )}

              <Box
                display={'flex'}
                flexDirection={'column'}
                flex={1}
                sx={{ marginLeft: 2, marginTop: 2 }}
              >
                <Box justifyContent={'start'}>
                  <CustomTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {product.name}
                  </CustomTypography>

                  <CustomTypography variant="subtitle2" maxWidth={'450px'}>
                    {product.description}
                  </CustomTypography>
                </Box>
              </Box>
            </CustomBox>
            <CustomGrid container justifyContent="center" alignItems="center">
              <Grid item md={12} sm={12} xs={12}>
                <ProductVariation
                  variations={product.variations}
                  onChange={HandleVariationsBodyChange}
                  initial={handleInitial}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box
                  sx={{ marginBottom: { xs: '70px', sm: '0px', md: '0px' } }}
                >
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Cambios adicionales"
                    name="notes"
                    multiline
                    rows={3}
                    helperText="Por ejemplo: Sin lechuga"
                    onChange={handleChangeNotes}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12} mt={2}>
                <CustomCard>
                  <Box flex={1} mt={1} mb={1}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography ml={2} mr={2} fontWeight={'bold'}>
                        Cantidad
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={decreaseQuantity}
                      >
                        <Remove />
                      </Button>
                      <Typography ml={2} mr={2}>
                        {quantity}
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={increaseQuantity}
                      >
                        <Add />
                      </Button>
                    </Box>

                    <Box display={'flex'} alignItems={'center'} sx={{ p: 1 }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleAddToCart}
                      >
                        Agregar a mi orden
                      </Button>
                      <Typography ml={1} variant="h5">
                        {toCurrencyFormat(ProductWithAddedValue() * quantity)}
                      </Typography>
                    </Box>
                  </Box>
                </CustomCard>
              </Grid>
            </CustomGrid>
          </Paper>
        </Container>
      </Modal>
    );
  }
};
