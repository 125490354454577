import React, { useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, styled } from '@mui/material';
import { toCurrencyFormat } from '../../../../utils/formatter';
import { useNavigate } from 'react-router-dom';
import { BranchMenu, BranchMenuCategory, Item } from 'types/Branch';
import { Product } from '../../../pages/Product';

type BranchMenuTabPanelProps = {
  branchMenus: BranchMenu[];
  branchId: number;
};

export const BranchMenuTabPanel: React.FC<BranchMenuTabPanelProps> = ({
  branchMenus,
  branchId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{
    branchId: number;
    productId: number;
  } | null>(null);

  const handleOpenModal = (branchId: number, productId: number) => {
    setSelectedProduct({ branchId, productId });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  return (
    <>
      {branchMenus.map((menu, i) => {
        return (
          <MenuPanel
            key={i}
            branchMenuCategories={menu.categories}
            branchId={branchId}
            onProductClick={handleOpenModal}
          />
        );
      })}
      {selectedProduct && (
        <Product
          open={openModal}
          onClose={handleCloseModal}
          branchId={selectedProduct.branchId}
          productId={selectedProduct.productId}
        />
      )}
    </>
  );
};

type MenuPanelProps = {
  branchMenuCategories: BranchMenuCategory[];
  branchId: number;
  onProductClick: (branchId: number, productId: number) => void;
};

const MenuPanel: React.FC<MenuPanelProps> = ({
  branchMenuCategories,
  branchId,
  onProductClick,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollRefs = branchMenuCategories.reduce(
    (acc, _, index) => {
      acc[index] = useRef<HTMLDivElement>(null);
      return acc;
    },
    {} as { [key: number]: React.RefObject<HTMLDivElement> },
  );

  const [currentCategory, setCurrentCategory] = useState<number | null>(null);

  const scrollToCategory = (index: number) => {
    if (scrollRefs[index]?.current) {
      const yOffset = scrollRefs[index]?.current?.offsetTop;
      if (yOffset !== undefined) {
        window.scrollTo({ top: yOffset - 150, behavior: 'smooth' });
        setCurrentCategory(index);
      }
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        sx={{
          position: 'sticky',
          top: { xs: 60, sm: 64, md: 75 },
          backgroundColor: '#fff',
          zIndex: 999,
          maxWidth: { xs: '100vw', sm: '100%' },
        }}
      >
        <Tabs
          sx={{ maxWidth: { xs: '100vw', sm: '100%' } }}
          variant="scrollable"
          value={value}
          onChange={handleChange}
        >
          {branchMenuCategories.map((cat, i) => (
            <Tab key={i} label={cat.name} onClick={() => scrollToCategory(i)} />
          ))}
        </Tabs>
      </Box>
      {branchMenuCategories.map((branchMenuCategory, i) => (
        <Box
          sx={{
            margin: '20px',
          }}
          key={i}
          ref={scrollRefs[i]}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {branchMenuCategory.name}
          </Typography>
          <CategoryProductList
            branchId={branchId}
            items={branchMenuCategory.items}
            onProductClick={onProductClick}
          />
        </Box>
      ))}
    </>
  );
};

const CustomBox = styled(Box)(({ theme }) => ({
  marginTop: 8,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: 125,
      height: 125,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: 80,
      height: 80,
    },
    '& .MuiTypography-root': {
      fontSize: '1rem',
    },
    '& .MuiBox-root': {
      margin: 3,
    },
  },
}));

type CategoryProductListProps = {
  items: Item[];
  branchId: number;
  onProductClick: (branchId: number, productId: number) => void;
};

const CategoryProductList: React.FC<CategoryProductListProps> = ({
  items,
  branchId,
  onProductClick,
}) => {
  const navigate = useNavigate();

  const OpenProduct = (productId: number) => {
    onProductClick(branchId, productId);
  };

  if (items) {
    return (
      <>
        {items.map((item) => (
          <Box
            key={item.product.id}
            onClick={() => OpenProduct(item.product.id)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <CustomBox>
              <Box display={'flex'}>
                {item.product.image && (
                  <Box mr={1}>
                    <img
                      width={150}
                      height={150}
                      src={item.product.image}
                      style={{
                        borderRadius: '5px',
                        aspectRatio: '1/1',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                )}
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                  <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {item.product.name}
                  </Typography>
                  <Typography style={{ fontSize: 15 }}>
                    {item.product.description}
                  </Typography>
                </Box>

                <Box alignSelf={'self-end'}>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'flex', justifyContent: 'end' }}
                  >
                    {toCurrencyFormat(item.product.price)}
                  </Typography>
                </Box>
              </Box>
            </CustomBox>
            <Divider sx={{ mb: 0.5 }}></Divider>
          </Box>
        ))}
      </>
    );
  }
};
