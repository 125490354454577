import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BranchCard } from '../../../components/partials/Branches/BranchCard';
import routesConstants from 'utils/routesConstants';
import { useAppSelector } from 'redux/hook';
import chaletApi from 'api/chaletApi';
import InfoIcon from '@mui/icons-material/Info';
import { getCurrentWeekDay } from 'utils/branchSchedule';

const initialLimit = 10;

const BranchesByProductType: React.FC = () => {
  const { sectorId } = useAppSelector((state) => state.locationSlice);
  const { type } = useParams();

  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const currentDay = getCurrentWeekDay();
  const navigate = useNavigate();
  const OpenShop = (branchId: number) => {
    navigate(routesConstants.branch(branchId.toString()));
  };

  const [fetchBranches, branchResult] =
    chaletApi.useLazyGetBranchesByProductTypeQuery();

  useEffect(() => {
    fetchData();
  }, [offset, type]);

  const fetchData = () => {
    if (type && sectorId) {
      const body = {
        type,
        sectorId: sectorId,
        offset,
        limit: initialLimit,
        day: currentDay,
      };
      fetchBranches(body).then((result) => {
        if (result.data && result.data.length < offset + initialLimit) {
          setHasMore(false);
        }
      });
    }
  };

  const handleEndReached = () => {
    if (!branchResult.isFetching && hasMore) {
      setOffset((prev) => prev + initialLimit);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      branchResult.isFetching
    ) {
      return;
    } else {
      handleEndReached();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [branchResult.isFetching]);

  if (branchResult.isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  if (branchResult.data?.length === 0) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 4,
          }}
          elevation={0}
        >
          <CardMedia>
            <InfoIcon color="primary" sx={{ fontSize: 60 }} />
          </CardMedia>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              textAlign={'center'}
              gutterBottom
            >
              No hay sucursales disponibles
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign={'center'}
            >
              Aún no hay sucursales disponibles para la categoría "{type}".
              Inténtalo más tarde.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  if (branchResult.data) {
    return (
      <Container maxWidth="xl">
        <Box
          display={'flex'}
          justifyContent={'center'}
          sx={{
            maxWidth: { xs: '100vw', sm: '100vw', md: '100%' },
            bgcolor: 'background.paper',
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex', md: 'flex' },
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: { xs: 'block', sm: 'flex', md: 'flex' },
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {branchResult.data.map((branch) => (
                <BranchCard
                  key={branch.id}
                  branch={branch}
                  OpenShop={OpenShop}
                />
              ))}
            </Box>
          </Box>
        </Box>
        {(branchResult.isLoading || branchResult.isFetching) && (
          <Box
            sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
            padding={4}
          >
            <Box>
              <CircularProgress size={100} color="primary" />
            </Box>
          </Box>
        )}
      </Container>
    );
  }

  return null;
};

export default BranchesByProductType;
