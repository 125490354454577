import { Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './ui/components/common/footer/Footer';
import { PrivacyPolicy } from './ui/pages/privacy_policy';
import { Appbar } from './ui/components/common/appbar/Appbar';
import { Orders } from './ui/pages/orders';
import { NearBranch } from './ui/pages/NearBranch';
import { Branch } from './ui/pages/Branch';
import { Home } from './ui/pages/home';
import { Login } from './ui/pages/Auth/login';
import { Register } from './ui/pages/Auth/register';
import { AboutUs } from 'ui/pages/profile/AboutUs';
import { FrequentQuestions } from 'ui/pages/profile/FrequentQuestions';
import { ClientService } from 'ui/pages/profile/ClientService';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import chaletApi from 'api/chaletApi';
import { useEffect } from 'react';
import { addLocation } from 'redux/slices/locationSlice';
import { ShopRegisterForm } from 'ui/pages/ShopRegisterForm/ShopRegisterForm';
import { Profile, EditProfile } from 'ui/pages/profile';
import DeleteAccount from 'ui/pages/DeleteAccount/DeleteAccount';
import routesConstants from 'utils/routesConstants';
import BranchesByProductType from 'ui/pages/NearBranch/BranchesByProductType/BranchesByProductType';
import BranchesByProductCategory from 'ui/pages/NearBranch/BranchesByProductCategory/BranchesByProductCategory';

const AppRouter = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isLoggedIn, userId } = useAppSelector((state) => state.userAuthSlice);
  const { uniqueId } = useAppSelector((state) => state.device);

  let hideAppbarComponent =
    location.pathname.includes('/shop/') ||
    location.pathname.includes('/product/') ||
    location.pathname === '/';

  const hideFooterComponent =
    location.pathname.includes('/shop/') ||
    location.pathname.includes('/product/');

  const { data: userData } = chaletApi.useGetUserByIdQuery(userId || 1, {
    skip: !isLoggedIn || !userId,
  });

  const { data: addressData } = chaletApi.useGetOneUserAddressQuery(
    userData?.config?.lastLocationId || 1,
    { skip: !userData?.config?.lastLocationId },
  );

  const [getDevice] = chaletApi.useGetDeviceMutation();

  useEffect(() => {
    if (!isLoggedIn && uniqueId === '') {
      getDevice({});
    }
  }, [isLoggedIn, uniqueId, getDevice]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (addressData) {
      const updateLocation = {
        id: addressData.id,
        name: addressData.name,
        address: addressData.address,
        reference: addressData.reference,
        location: addressData.location,
        sectorId: addressData.sectorId,
      };
      dispatch(addLocation(updateLocation));
    }
  }, [addressData, dispatch]);

  if (isLoggedIn && location.pathname === '/') {
    hideAppbarComponent = false;
  }

  return (
    <>
      <Appbar hideComponents={hideAppbarComponent}>
        <Routes>
          <Route path={routesConstants.initial} element={<Home />} />
          <Route path={routesConstants.orders} element={<Orders />} />
          <Route path={routesConstants.home} element={<NearBranch />}>
            <Route
              path={routesConstants.branchByProductTypeDef}
              element={<BranchesByProductType />}
            />
            <Route
              path={routesConstants.branchByProductCategory}
              element={<BranchesByProductCategory />}
            />
          </Route>
          <Route path={routesConstants.branchDef} element={<Branch />} />

          <Route path={routesConstants.login} element={<Login />} />
          <Route path={routesConstants.profile} element={<Profile />} />
          <Route path={routesConstants.aboutUs} element={<AboutUs />} />
          <Route path={routesConstants.signUp} element={<Register />} />
          <Route path={routesConstants.faq} element={<FrequentQuestions />} />
          <Route
            path={routesConstants.customerService}
            element={<ClientService />}
          />
          <Route
            path={routesConstants.termsAndConditions}
            element={<PrivacyPolicy />}
          />
          <Route
            path={routesConstants.shopSignUp}
            element={<ShopRegisterForm />}
          />
          <Route
            path={routesConstants.deleteAccount}
            element={<DeleteAccount />}
          />
          <Route path="/editar_perfil" element={<EditProfile />} />
          <Route path="*" element={<div>Not found</div>} />
        </Routes>
      </Appbar>
      {!hideFooterComponent && <Footer />}
    </>
  );
};

export default AppRouter;
