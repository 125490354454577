import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowForwardIos, Check, Close } from '@mui/icons-material';
import { HorizontalProductCard } from './HorizontalProductCard';
import { ConfirmOrder } from './ConfirmOrder';
import { toCurrencyFormat } from '../../../../utils/formatter';
import { useAppSelector } from 'redux/hook';
import chaletApi from 'api/chaletApi';
import { CartDrawer } from './CartDrawer';
import { DeliveryData } from 'types/DeliveryData';
import { StepperBox } from './style';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import routesConstants from 'utils/routesConstants';
import { Branch } from 'types/Branch';

const steps = [1, 2];

type CartProps = {
  open: boolean;
  handleStateViewDrawer: () => void;
  shopId: number;
  cart: Branch;
};

export const Cart: React.FC<CartProps> = ({
  open,
  handleStateViewDrawer,
  shopId,
  cart,
}) => {
  const { userId, isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  const [createOrder] = chaletApi.useCreateOrderMutation();
  const [getUser] = chaletApi.useLazyGetUserByIdQuery();

  const [lastLocation, setLastLocation] = React.useState<{
    addressId: number;
    addressLocation: { latitude: number; longitude: number };
    address: string;
    addressReference: string;
  } | null>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [deliveryData, setDeliveryData] = useState<DeliveryData | null>(null);
  const [openAlert, setOpenAlert] = React.useState(true);

  const [orderProcessId, setOrderProcessId] = useState<number | null>(null);
  const [orderProcessStepId, setOrderProcessStepId] = useState<number | null>(
    null,
  );

  // this state is used to validate if the user has a valid location in ConfirmOrder and RouteOrder components
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false);

  const maxSteps = steps.length;

  const navigate = useNavigate();

  const { branchId } = useParams();
  const id = parseInt(branchId || '0', 10);

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .unwrap()
        .then((payload) => {
          const lastLocation = {
            addressId: payload.selectedAddress.id,
            addressLocation: {
              latitude: payload.selectedAddress.location.coordinates[1],
              longitude: payload.selectedAddress.location.coordinates[0],
            },
            address: payload.selectedAddress.address,
            addressReference: payload.selectedAddress.reference,
          };

          setLastLocation(lastLocation);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, isLoggedIn]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeliveryData = (data: DeliveryData | null) => {
    setDeliveryData(data);
  };

  const handleSetOrderProcessId = (id: number) => {
    setOrderProcessId(id);
  };

  const handleSetOrderProcessStepId = (id: number) => {
    setOrderProcessStepId(id);
  };

  const handleValidateLocation = async (isValidate: boolean) => {
    setIsValidLocation(isValidate);
  };

  const handlePostEvent = async () => {
    try {
      if (cart.userCart) {
        const orderDetails = cart.userCart.items.map((item) => {
          const optionsArray = Object.values(
            item.variations.map((v) => v.option),
          );

          const variations = optionsArray.map((option) => ({
            variationOptionId: option.id,
            increase: option.addedValue,
          }));

          return {
            itemId: item.id,
            quantity: item.quantity,
            name: item.name,
            productId: item.productId,
            productPrice: item.price,
            variations: variations,
            notes: item.notes,
          };
        });

        toast.promise(
          createOrder({
            cartId: cart.userCart.id,
            userId,
            shopId,
            branchId: id,
            addressId: lastLocation?.addressId,
            addressLocation: lastLocation?.addressLocation,
            address: lastLocation?.address,
            addressReference: lastLocation?.addressReference,
            orderDetails,
            orderProcessId: orderProcessId,
            currentOrderProcessStepId: orderProcessStepId,
            deliveryPrice: deliveryData?.deliveryPrice,
          })
            .unwrap()
            .then(() => {
              navigate(routesConstants.orders);
            })
            .catch((error) => console.error('rejected', error)),
          {
            loading: 'Guardando...',
            success: <b>Orden Creada</b>,
            error: <b>Ha ocurrido un error</b>,
          },
        );
      }
    } catch (error) {
      console.error('Error en la acción POST:', error);
    }
  };

  return (
    <CartDrawer
      open={open}
      handleStateViewDrawer={handleStateViewDrawer}
      activeStep={activeStep}
      maxSteps={maxSteps}
      back={handleBack}
    >
      {isLoggedIn ? (
        cart.userCart &&
        cart.userCart.items &&
        cart.userCart.items.length > 0 ? (
          <Box>
            <Box>
              {activeStep === steps.length ? (
                <Box>
                  <Typography variant="h5">¡Orden confirmada!</Typography>
                </Box>
              ) : (
                <Box>
                  {activeStep === 0 && (
                    <>
                      <Box sx={{ marginBottom: { xs: '75px', sm: '0px' } }}>
                        <HorizontalProductCard cart={cart.userCart} />
                      </Box>
                      <StepperBox>
                        <Box flex={1}>
                          <Stack direction={'column'}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography
                                flex={1}
                                sx={{ fontSize: 20, fontWeight: 'bold', mt: 1 }}
                              >
                                Total:
                              </Typography>
                              <Typography sx={{ fontSize: 20, mt: 1 }}>
                                {toCurrencyFormat(cart.userCart.total)}
                              </Typography>
                            </Box>

                            <Button
                              endIcon={<ArrowForwardIos />}
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              fullWidth
                            >
                              Siguiente
                            </Button>
                          </Stack>
                        </Box>
                      </StepperBox>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      {!isLoggedIn && (
                        <Box sx={{ width: '100%' }}>
                          <Collapse in={openAlert}>
                            <Alert
                              severity="warning"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setOpenAlert(false);
                                  }}
                                >
                                  <Close fontSize="inherit" />
                                </IconButton>
                              }
                              sx={{ mb: 2 }}
                            >
                              Debes iniciar sesión para poder realizar un pedido
                            </Alert>
                          </Collapse>
                        </Box>
                      )}
                      {cart && (
                        <Box>
                          <ConfirmOrder
                            branch={cart}
                            total={cart.userCart.total}
                            setDeliveryData={handleDeliveryData}
                            currentOrderProcessId={handleSetOrderProcessId}
                            currentOrderProcessStepId={
                              handleSetOrderProcessStepId
                            }
                            setIsValidLocation={handleValidateLocation}
                            isValidLocation={isValidLocation}
                          ></ConfirmOrder>
                        </Box>
                      )}
                      {isValidLocation && (
                        <StepperBox>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePostEvent}
                            fullWidth
                            disabled={
                              !deliveryData || !isLoggedIn || !lastLocation
                            }
                            startIcon={<Check />}
                          >
                            Confirmar Orden
                          </Button>
                        </StepperBox>
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Typography variant="body2">
            Tu carrito está vacío para esta tienda.
          </Typography>
        )
      ) : cart.userCart &&
        cart.userCart.items &&
        cart.userCart.items.length > 0 ? (
        <Box>
          <Box>
            {activeStep === steps.length ? (
              <Box>
                <Typography variant="h5">¡Orden confirmada!</Typography>
              </Box>
            ) : (
              <Box>
                {activeStep === 0 && (
                  <>
                    <Box sx={{ marginBottom: { xs: '75px', sm: '0px' } }}>
                      <HorizontalProductCard cart={cart.userCart} />
                    </Box>
                    <StepperBox>
                      <Box flex={1}>
                        <Stack direction={'column'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography
                              flex={1}
                              sx={{ fontSize: 20, fontWeight: 'bold', mt: 1 }}
                            >
                              Total:
                            </Typography>
                            <Typography sx={{ fontSize: 20, mt: 1 }}>
                              {toCurrencyFormat(cart.userCart.total)}
                            </Typography>
                          </Box>

                          <Button
                            endIcon={<ArrowForwardIos />}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            fullWidth
                          >
                            Siguiente
                          </Button>
                        </Stack>
                      </Box>
                    </StepperBox>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    {!isLoggedIn && (
                      <Box sx={{ width: '100%' }}>
                        <Collapse in={openAlert}>
                          <Alert
                            severity="warning"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                            sx={{ mb: 2 }}
                          >
                            Debes iniciar sesión para poder realizar un pedido
                          </Alert>
                        </Collapse>
                      </Box>
                    )}
                    {cart && (
                      <Box>
                        <ConfirmOrder
                          branch={cart}
                          total={cart.userCart.total}
                          setDeliveryData={handleDeliveryData}
                          currentOrderProcessId={handleSetOrderProcessId}
                          currentOrderProcessStepId={
                            handleSetOrderProcessStepId
                          }
                          setIsValidLocation={handleValidateLocation}
                          isValidLocation={isValidLocation}
                        ></ConfirmOrder>
                      </Box>
                    )}
                    {isValidLocation && (
                      <StepperBox>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePostEvent}
                          fullWidth
                          disabled={
                            !deliveryData || !isLoggedIn || !lastLocation
                          }
                          startIcon={<Check />}
                        >
                          Confirmar Orden
                        </Button>
                      </StepperBox>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="body2">
          Tu carrito está vacío para esta tienda.
        </Typography>
      )}
    </CartDrawer>
  );
};
