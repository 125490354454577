import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useDispatch } from 'react-redux';
import { addLocation } from '../../../../../redux/slices/locationSlice';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import chaletApi from '../../../../../api/chaletApi';
import { address } from 'types/Address';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import routesConstants from 'utils/routesConstants';
import { useAppSelector } from 'redux/hook';

type FormData = {
  directionOptions: string;
  description: string;
  reference?: string;
  nameDirection?: string;
};

type addressProps = {
  onSubmit: () => void;
  address?: address;
  isLoggedIn: boolean;
  onClose?: () => void;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
};

const containerStyle = {
  marginTop: '15px',
  display: 'flex',
  height: '400px',
};

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
const librarie = ['places'];
const steps = ['Ingresar direccion', 'Editar direccion'];

const schema = yup.object().shape({
  directionOptions: yup.string().required('debes seleccionar una opcion'),
  description: yup.string().required('es requerido'),
  reference: yup.string(),
  nameDirection: yup.string().when('directionOptions', {
    is: 'otro',
    then: (schema: any) => schema.required('es requerido'),
    otherwise: (schema: any) => schema.notRequired(),
  }),
});

export const AddUserAddress: React.FC<addressProps> = ({
  onSubmit,
  address,
  isLoggedIn,
  onClose,
  handleBack,
  handleNext,
  activeStep,
}) => {
  const { sectorId } = useAppSelector((state) => state.device);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: librarie as any,
  });

  const [addressId, setAddressId] = useState<number>(0);
  const [selected, setSelected] = useState<google.maps.LatLngLiteral | null>(
    null,
  );
  const [description, setDescription] = useState<string>('');
  const [nameDirection, setNameDirection] = useState<string>('');
  const [reference, setReference] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState('casa');
  const [createAddress] = chaletApi.useSaveUserAddressMutation();
  const [findAndSetDeviceSector] =
    chaletApi.useFindAndSetDeviceSectorMutation();
  const [updateAddress] = chaletApi.useUpdateUserAddressMutation();

  const [updateLastLocation] = chaletApi.useUpdateLastLocationIdMutation();

  const { uniqueId } = useAppSelector((state) => state.device);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && uniqueId && selected) {
      findAndSetDeviceSector({
        uniqueId,
        latitude: selected?.lat || 0,
        longitude: selected?.lng || 0,
      });
    }
  }, [isLoggedIn, uniqueId, selected]);

  useEffect(() => {
    if (address) {
      setAddressId(address.id || 0);
      setNameDirection(address.name || '');
      setDescription(address.address || '');
      setReference(address.reference || '');
      setSelected({
        lat: address.location.coordinates[1],
        lng: address.location.coordinates[0],
      });
    }
  }, [address]);

  const handleMarkerDrag = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setSelected({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const Submit = (data: FormData) => {
    if (!isLoggedIn) {
      const updateLocation = {
        name:
          data.directionOptions === 'otro'
            ? data.nameDirection
            : data.directionOptions,
        address: data.description,
        reference: data.reference || '',
        location: {
          coordinates: [selected?.lng, selected?.lat] as [number, number],
        },
        sectorId,
      };
      dispatch(addLocation(updateLocation));
      navigate(routesConstants.home);

      if (onClose) {
        onClose();
      }
    } else {
      if (address) {
        //EditMode
        const addressData = {
          id: addressId,
          name:
            data.directionOptions === 'otro'
              ? data.nameDirection
              : data.directionOptions,
          address: data.description,
          reference: data.reference || '',
          latitude: selected?.lat || 0,
          longitude: selected?.lng || 0,
        };

        updateAddress(addressData)
          .unwrap()
          .then((payload) => {
            onSubmit();
          })
          .catch((error) => console.error('rejected', error));
      } else {
        //SaveMode
        const addressData = {
          name:
            data.directionOptions === 'otro'
              ? data.nameDirection
              : data.directionOptions,
          address: data.description,
          reference: data.reference || '',
          latitude: selected?.lat || 0,
          longitude: selected?.lng || 0,
        };
        createAddress(addressData)
          .unwrap()
          .then((payload) => {
            onSubmit();

            updateLastLocation(payload.data.id);

            dispatch(addLocation(payload.data));
          })
          .catch((error) => console.error('rejected', error));
      }
    }
  };

  if (!isLoaded) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Box>
        {activeStep === steps.length ? (
          <Box>
            <Typography variant="h5">
              ¡Direccion Ingresada correctamente!
            </Typography>
          </Box>
        ) : (
          <Box>
            {activeStep === 0 && (
              <Box>
                {!address && isLoaded && (
                  <Box>
                    <PlacesAutocomplete
                      setSelected={setSelected}
                      setDescription={setDescription}
                    />
                  </Box>
                )}

                {selected && (
                  <Box>
                    <Typography
                      variant="caption"
                      color={'red'}
                      textAlign={'center'}
                    >
                      Nota: Mueve el marcador para ajustar el punto de entrega
                    </Typography>
                    <GoogleMap
                      zoom={18}
                      center={selected}
                      mapContainerStyle={containerStyle}
                      options={{ mapTypeControl: false }}
                    >
                      <MarkerF
                        position={selected}
                        draggable={true}
                        onDragEnd={handleMarkerDrag}
                      />
                    </GoogleMap>
                  </Box>
                )}
                {selected !== null && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    columnGap={2}
                    mt={2}
                  >
                    <Button
                      endIcon={<ArrowForwardIos />}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={selected === null}
                      fullWidth
                    >
                      Siguiente
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {activeStep === 1 && (
              <Box
                mt={2}
                component={'form'}
                display={'flex'}
                rowGap={1}
                flexDirection={'column'}
                onSubmit={handleSubmit(Submit)}
              >
                <Controller
                  name="directionOptions"
                  control={control}
                  defaultValue="casa"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setSelectedOption(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="casa"
                        control={<Radio />}
                        label="Casa"
                      />
                      <FormControlLabel
                        value="trabajo"
                        control={<Radio />}
                        label="Trabajo"
                      />
                      <FormControlLabel
                        value="otro"
                        control={<Radio />}
                        label="Otro"
                      />
                    </RadioGroup>
                  )}
                />
                <Typography variant="caption" color="red">
                  {errors.directionOptions?.message}
                </Typography>
                {selectedOption === 'otro' && (
                  <TextField
                    defaultValue={nameDirection}
                    label="Etiqueta: ej: casa de mi mamá"
                    error={!!errors.nameDirection}
                    helperText={errors.nameDirection?.message}
                    {...register('nameDirection')}
                  />
                )}
                <TextField
                  defaultValue={description}
                  label="Dirección"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  {...register('description')}
                />
                <TextField
                  defaultValue={reference}
                  label="Referencia"
                  {...register('reference')}
                />

                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  columnGap={2}
                  mt={2}
                >
                  <Button variant="contained" type="submit" fullWidth>
                    Guardar Direccion
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

interface PlacesAutocompleteProps {
  setSelected: (value: any) => void;
  setDescription: (value: any) => void;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  setSelected,
  setDescription,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'sv' },
    },
    debounce: 300,
  });
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      setValue(description, false);
      clearSuggestions();

      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setSelected({ lat, lng });
        setDescription(description);
      });
    };

  const renderSuggestions = () => (
    <List>
      {data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <ListItem
            disablePadding
            key={place_id}
            onClick={handleSelect(suggestion)}
          >
            <ListItemButton>
              <ListItemText primary={main_text} secondary={secondary_text} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const newPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        getGeocode({ location: newPosition }).then((results) => {
          const address = results[0].formatted_address;
          setValue(address, false);
          clearSuggestions();
          setSelected(newPosition);
          setDescription(address);
        });

        console.log(newPosition);
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          value={value}
          onChange={handleInput}
          disabled={!ready}
          label="Ingresa tu dirección"
          fullWidth
        />
        <Button variant="contained" onClick={getLocation}>
          <MyLocationIcon />
        </Button>
      </Box>
      {status === 'OK' && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            zIndex: 1000,
            bgcolor: 'background.paper',
            boxShadow: 1,
            borderRadius: 1,
          }}
        >
          {renderSuggestions()}
        </Box>
      )}
    </Box>
  );
};

//Por el momento esta funcion no esta habilitada
/* const DeliveryDay = ({ currentDay, setSelectedDate, selectedDate }) => {
  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="simple-select-label">Fecha de entrega</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={selectedDate}
        label="Selecciona una fecha"
        onChange={handleChange}
      >
        <MenuItem value={currentDay}>Entrega Hoy</MenuItem>
      </Select>
    </FormControl>
  );
}; */
