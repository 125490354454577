import { Box, Typography } from '@mui/material';
import { io, Socket } from 'socket.io-client';
import { useEffect, useRef, useState } from 'react';
import { constants } from '../../../../../utils/constants';
import DestinationIcon from '../../../../../assets/ic_marker_destination.svg';
import shopIcon from '../../../../../assets/ic_store.svg';
import DriverIcon from '../../../../../assets/ic_marker_driver.svg';
import { Order } from 'types/Order';
import { RouteMap } from '../../RouteMap';

// 'https://shopapp-332907.uc.r.appspot.com'

const getSocket = () => {
  if (constants.IS_DEV) {
    return io('localhost:5000');
  } else {
    return io();
  }
};

type DeliveryMapType = {
  order: Order;
};

type MapType = {
  id: string;
  coordinates: [number, number];
  icon?: string;
};

type Location = {
  latitude: number;
  longitude: number;
};

export const DeliveryMap: React.FC<DeliveryMapType> = ({ order }) => {
  const destinationLocation: MapType = {
    id: 'destination',
    coordinates: [
      order.route.destination.location.coordinates[0],
      order.route.destination.location.coordinates[1],
    ],
  };

  const branchLocation: MapType = {
    id: 'branch',
    coordinates: [
      order.branch.location.coordinates[0],
      order.branch.location.coordinates[1],
    ],
    icon: shopIcon,
  };

  const [driverMapMarker, setDriverMapMarker] = useState<MapType | null>(null);
  const [socket, setSocket] = useState<any>(null);

  const destinationMarker: MapType = destinationLocation;
  const isDelivery = order.currentOrderProcessStep.correlative === 5;
  const mapMarkers: MapType[] = [destinationMarker, branchLocation];

  useEffect(() => {
    if (isDelivery) {
      const lastLocation = order.lastLocation.location;
      if (lastLocation !== undefined) {
        const driverLocation = {
          id: 'driver',

          coordinates: [lastLocation.longitude, lastLocation.latitude] as [
            number,
            number,
          ],
          icon: DriverIcon,
        };
        setDriverMapMarker(driverLocation);
      }
    }

    const socket = getSocket();
    socket.on('connect', () => {
      setSocket(socket);
      if (isDelivery) {
        listenDriverUpdates(order.route.id, socket);
      }
    });

    return () => {
      socket.disconnect();
      console.log('Socket disonnected');
    };
  }, []);

  const onDriverUpdate = (locationUpdate: Location) => {
    setDriverMapMarker({
      id: 'driver',

      coordinates: [locationUpdate.longitude, locationUpdate.latitude] as [
        number,
        number,
      ],
      icon: DriverIcon,
    });
  };

  const listenDriverUpdates = (deliveryId: number, socket: any) => {
    socket.emit('join', 'delivery_' + deliveryId);
    socket.on('update_driver_location', (location: Location) => {
      onDriverUpdate(location);
    });
  };

  if (driverMapMarker) {
    mapMarkers.push(driverMapMarker);
  }

  return (
    <>
      <RouteMap markers={mapMarkers} />
    </>
  );
};
