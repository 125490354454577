import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import chaletApi from '../../../../api/chaletApi';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VerificationCode } from '../verificationCode';
import { useAppSelector } from 'redux/hook';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone-lite';
import routesConstants from 'utils/routesConstants';

const schema = yup.object().shape({
  phone: yup
    .string()
    .max(9, 'máximo 8 digitos')
    .phone('SV', 'el número es invalido')
    .required('debe ingresar su número  de telefono'),
  name: yup.string().required('debe ingresar su nombre'),
});

const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};

type FormData = {
  phone: string;
  name: string;
};

export const Register = () => {
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [userRegister] = chaletApi.useLazyUserRegisterQuery();
  const [showVerification, setShowVerification] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);

  const { uniqueId, deviceCart } = useAppSelector((state) => state.device);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routesConstants.home);
    }
  }, [isLoggedIn]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    const phone = data.phone;
    const name = data.name;

    userRegister({ phone, name, uniqueId, carts: deviceCart })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          setPhone(phone);
          setShowVerification(true);

          toast.success('Codigo Enviado');
        } else {
          toast.error(payload.error);
        }
      })
      .catch((error) => {
        toast.error('Ingresa un numero válido');
      });
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item sx={{ width: 350 }}>
          <Paper sx={{ padding: '20px', borderRadius: '10px' }} elevation={4}>
            <Typography sx={{ mt: 1, mb: 1 }} variant="h4">
              Registro
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                type="text"
                label="Nombre"
                fullWidth
                sx={{ mt: 2, mb: 1.5 }}
                disabled={showVerification}
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register('name')}
              />

              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    disabled={showVerification}
                    type="tel"
                    inputMode="numeric"
                    label="Teléfono"
                    fullWidth
                    sx={{ mb: 1.5 }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onChange={(event) => {
                      const { value } = event.target;
                      field.onChange(MaskNumber(value)); // Actualiza el valor del campo
                    }}
                    value={field.value} // Asigna el valor del campo
                  />
                )}
              />

              {!showVerification && (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mb: 3 }}
                >
                  Registrarse
                </Button>
              )}

              {showVerification && (
                <VerificationCode phone={phone} isRegister={true} />
              )}
              <Stack
                direction={'row'}
                spacing={0.5}
                display={'flex'}
                justifyContent={'center'}
              >
                <Typography>¿Ya tienes una cuenta?</Typography>
                <Typography
                  color="primary"
                  component={Link}
                  to={routesConstants.login}
                >
                  Inicia Sesión
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
