import {
  Avatar,
  Stack,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
} from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ProductCategory } from 'types/ProductCategory';

type Props = {
  items: ProductCategory[];
  changeCategory: (id: number) => void;
};

export const CustomIconButtonList = ({ items, changeCategory }: Props) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md'),
  );
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollContainerRef.current;
        setShowScrollButtons(scrollWidth > clientWidth);
      }
    };

    // Verificar el overflow al montar el componente y cuando cambie el tamaño de la ventana
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [items]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box sx={{ position: 'relative', maxWidth: '90vw', overflow: 'hidden' }}>
      {showScrollButtons && (
        <IconButton
          onClick={() => handleScroll('left')}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
            display: showScrollButtons ? 'block' : 'none',
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      )}

      <Box
        ref={scrollContainerRef}
        sx={{
          display: 'flex',
          overflowX: isSmallScreen ? 'auto' : 'hidden',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {items.map((item, index) => (
          <Stack
            key={item.id}
            maxWidth={'80px'}
            alignItems={'center'}
            justifyContent={'top'}
            m={1}
          >
            <IconButton
              sx={{ width: 60, height: 60 }}
              onClick={() => changeCategory(item.id)}
            >
              <Avatar
                sx={{ width: 30, height: 30 }}
                variant="square"
                src={item.image}
              />
            </IconButton>
            <Typography
              textAlign={'center'}
              variant="subtitle2"
              color="text.secondary"
            >
              {item.name}
            </Typography>
          </Stack>
        ))}
      </Box>

      {showScrollButtons && (
        <IconButton
          onClick={() => handleScroll('right')}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
            display: showScrollButtons ? 'block' : 'none',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};
