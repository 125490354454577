import dayjs from 'dayjs';
import { ChaletApiResponse } from 'types/ChaletApiResponse';
import {
  Branch,
  BranchWithTodaySchedule,
  BranchWithTodayScheduleUI,
  Schedule,
} from 'types/Branch';
import {
  Featured,
  GetHomepageResponse,
} from 'types/Responses/GetHomepageResponse';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DaysOfTheWeek } from 'types/DaysOfTheWeek';
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const getCurrentWeekDay = () => {
  const dayOfWeek = dayjs().day() as keyof typeof DaysOfTheWeek;
  const mappedDay = DaysOfTheWeek[dayOfWeek];
  return mappedDay;
};

const getNow = (timezone: string) => {
  const now = dayjs().tz(timezone);
  return now;
};

const getBranchSchedule = (branch: BranchWithTodaySchedule) => {
  return branch.schedule[0];
};

const isWithinSchedule = (schedule: Schedule, branch: Branch) => {
  const now = getNow(branch.timezone);
  const startTime = dayjs(schedule.startTime, 'HH:mm:ss');
  const endTime = dayjs(schedule.endTime, 'H:mm:ss');

  return (
    now.isSame(startTime) ||
    now.isSame(endTime) ||
    (now.isAfter(startTime) && now.isBefore(endTime))
  );
};

const isBranchNotYetOpen = (schedule: Schedule, branch: Branch) => {
  const now = getNow(branch.timezone);
  const startTime = dayjs(schedule.startTime, 'HH:mm:ss');
  return now.isBefore(startTime);
};

const isBranchAlreadyClosed = (schedule: Schedule, branch: Branch) => {
  const now = getNow(branch.timezone);
  const endTime = dayjs(schedule.endTime, 'H:mm:ss');
  return now.isAfter(endTime);
};

type FeaturedWithTodayScheduleUI = Pick<Featured, 'type' | 'name'> & {
  data: BranchWithTodayScheduleUI[];
};

export const transformHomePageBranchesWithTodaySchedule: (
  response: ChaletApiResponse<GetHomepageResponse>,
) => Pick<
  GetHomepageResponse,
  'productCategories' | 'productTypes' | 'sectorId'
> & {
  branches: BranchWithTodayScheduleUI[];
  featured: FeaturedWithTodayScheduleUI[];
} = (response) => {
  const branchesWithScheduleData = response.data.branches.map((branch) => {
    const schedule = getBranchSchedule(branch);
    const parsedBranch = {
      ...branch,
      isWithinSchedule: isWithinSchedule(schedule, branch),
      isOpen: !schedule.closed,
      isBranchAlreadyClosed: isBranchAlreadyClosed(schedule, branch),
      isBranchNotYetOpen: isBranchNotYetOpen(schedule, branch),
      todaySchedule: schedule,
    };

    return parsedBranch;
  });

  const parsedFeatured = response.data.featured.map((featured) => {
    const featuredBranches = featured.data.map((branch) => {
      const schedule = getBranchSchedule(branch);

      return {
        ...branch,
        isWithinSchedule: isWithinSchedule(schedule, branch),
        isOpen: !schedule.closed,
        isBranchAlreadyClosed: isBranchAlreadyClosed(schedule, branch),
        isBranchNotYetOpen: isBranchNotYetOpen(schedule, branch),
        todaySchedule: schedule,
      };
    });

    return {
      ...featured,
      data: featuredBranches,
    };
  });

  return {
    ...response.data,
    branches: branchesWithScheduleData,
    featured: parsedFeatured,
  };
};

export const transformProductTypeWithTodaySchedule: (
  response: ChaletApiResponse<Branch[]>,
) => BranchWithTodayScheduleUI[] = (response) => {
  const branchesWithScheduleData = response.data.map((branch) => {
    const schedule = getBranchSchedule(branch);
    const parsedBranch = {
      ...branch,
      isWithinSchedule: isWithinSchedule(schedule, branch),
      isOpen: !schedule.closed,
      isBranchAlreadyClosed: isBranchAlreadyClosed(schedule, branch),
      isBranchNotYetOpen: isBranchNotYetOpen(schedule, branch),
      todaySchedule: schedule,
    };

    return parsedBranch;
  });

  return branchesWithScheduleData;
};

export const transformProductCategoryWithTodaySchedule: (
  response: ChaletApiResponse<Branch[]>,
) => BranchWithTodayScheduleUI[] = (response) => {
  const branchesWithScheduleData = response.data.map((branch) => {
    const schedule = getBranchSchedule(branch);
    const parsedBranch = {
      ...branch,
      isWithinSchedule: isWithinSchedule(schedule, branch),
      isOpen: !schedule.closed,
      isBranchAlreadyClosed: isBranchAlreadyClosed(schedule, branch),
      isBranchNotYetOpen: isBranchNotYetOpen(schedule, branch),
      todaySchedule: schedule,
    };

    return parsedBranch;
  });

  return branchesWithScheduleData;
};
