import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Avatar,
  CircularProgress,
  Button,
  Tabs,
  Tab,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';
import { BranchList } from 'ui/components/partials/Branches/BranchList';
import chaletApi from 'api/chaletApi';
import { useAppSelector } from 'redux/hook';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CustomIconButtonList } from 'ui/components/common/CustomIconButtonList';

export const NearBranch = () => {
  const [type, setType] = useState<string | null>(null);
  const [category, setCategory] = useState<number | null>(null);
  const { user } = useAppSelector((state) => state.user);
  const { location } = useAppSelector((state) => state.locationSlice);
  const userAddressId = user?.selectedAddressId;
  const navigate = useNavigate();
  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname === '/home') {
      setType(null);
      setCategory(null);
    }
  }, [currentLocation]);

  const changeType = (type: string) => {
    setCategory(null);
    setType(type);
    navigate(`/home/branches/product-type/${type}`);
  };

  const changeCategory = (category: number) => {
    setType(null);
    setCategory(category);
    navigate(`/home/branches/product-category/${category}`);
  };

  const {
    data: homepageData,
    error: homepageError,
    isLoading: homepageLoading,
  } = chaletApi.useGetHomepageQuery(
    userAddressId
      ? { userAddressId }
      : location?.coordinates
      ? { lat: location.coordinates[1], lng: location.coordinates[0] }
      : null,
    { skip: !userAddressId && !location?.coordinates },
  );

  if (homepageError) {
    toast.error('Error obteniendo las tiendas');
  }

  if (homepageLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  if (homepageData) {
    return (
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            maxWidth: { xs: '100vw', sm: '100%' },
            bgcolor: 'background.paper',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            padding: '8px 16px',
          }}
        >
          {homepageData?.productTypes.map((productType) => (
            <Button
              key={productType.productType.key}
              onClick={() => {
                changeType(productType.productType.key);
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 12px',
              }}
            >
              <Avatar
                variant="square"
                sx={{
                  mt: 2,
                  width: { xs: '60px', md: '75px' },
                  height: { xs: '60px', md: '75px' },
                  borderRadius: '5px',
                }}
                src={productType.productType.icon}
              />
              {productType.productType.name}
            </Button>
          ))}
        </Box>

        <Box
          display={'flex'}
          justifyContent={'center'}
          sx={{
            maxWidth: { xs: '100vw', sm: '100%' },
            bgcolor: 'background.paper',
          }}
        >
          {homepageData.productCategories && (
            <CustomIconButtonList
              items={homepageData.productCategories}
              changeCategory={changeCategory}
            />
          )}
        </Box>

        {type || category ? (
          <Outlet />
        ) : (
          <BranchList
            branches={homepageData.branches}
            featured={homepageData.featured}
          />
        )}
      </Container>
    );
  }

  return null;
};
