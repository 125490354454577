import { Box, Divider, Grid, Radio } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { CustomTypography } from '../style';
import { toCurrencyFormat } from 'utils/formatter';
import chaletApi from 'api/chaletApi';

type OrderProcessesProps = {
  currentOrderProcessId: (id: number) => void;
  currentOrderProcessStepId: (id: number) => void;
  changeCurrentOrderProcess: (id: number) => void;
};

export const OrderProcesses: React.FC<OrderProcessesProps> = ({
  currentOrderProcessId,
  currentOrderProcessStepId,
  changeCurrentOrderProcess,
}) => {
  const { data: OrderProcessesData } = chaletApi.useGetOrderProcessesQuery();
  const [selectedProcess, setSelectedProcess] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  useEffect(() => {
    if (OrderProcessesData && OrderProcessesData.length > 0 && selectedProcess === null) {
      setSelectedProcess(OrderProcessesData[0].id);
    }
  }, [OrderProcessesData]);

  useEffect(() => {
    if (selectedProcess !== null && OrderProcessesData) {
      const process = OrderProcessesData.find(
        (orderProcess) => orderProcess.id === selectedProcess,
      );
      if (process) {
        const step = process.steps.find((step: any) => step.correlative === 1);
        if (step) {
          setSelectedStep(step.id);
        } else {
          setSelectedStep(null);
        }
      }
    }
  }, [selectedProcess, OrderProcessesData]);

  useEffect(() => {
    if (selectedProcess && selectedStep) {
      currentOrderProcessId(selectedProcess);
      currentOrderProcessStepId(selectedStep);
      changeCurrentOrderProcess(selectedProcess);
    }
  }, [selectedProcess, selectedStep]);

  const handleRadioChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedProcess = parseInt(event.target.value);
    setSelectedProcess(newSelectedProcess);

    const process = OrderProcessesData?.find(
      (orderProcess) => orderProcess.id === newSelectedProcess,
    );
    if (process) {
      const step = process.steps.find((step: any) => step.correlative === 1);
      if (step) {
        setSelectedStep(step.id);
      } else {
        setSelectedStep(null);
      }
    }
  }, [OrderProcessesData]);

  const handleBoxClick = useCallback((id: number) => {
    setSelectedProcess(id);

    const process = OrderProcessesData?.find(
      (orderProcess) => orderProcess.id === id,
    );
    if (process) {
      const step = process.steps.find((step: any) => step.correlative === 1);
      if (step) {
        setSelectedStep(step.id);
      } else {
        setSelectedStep(null);
      }
    }
  }, [OrderProcessesData]);

  if (!OrderProcessesData) {
    return null;
  }

  return (
    <Grid item md={12} sm={12} xs={12} mt={1} sx={{ cursor: 'pointer' }}>
      {OrderProcessesData.map((orderProcess) => (
        <Box
          key={orderProcess.id}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          border={1}
          borderColor="error.main"
          bgcolor="#ffeded"
          borderRadius={1}
          p={1}
          mb={1}
          onClick={() => handleBoxClick(orderProcess.id)}
        >
          <Box flex={0} mr={1}>
            <Radio
              checked={selectedProcess === orderProcess.id}
              onChange={handleRadioChange}
              value={orderProcess.id.toString()}
              name="radio-buttons"
            />
          </Box>
          <Box flex={1}>
            <CustomTypography
              variant="body1"
              sx={{ fontWeight: 'bold', color: 'error.main' }}
            >
              {orderProcess.name}
            </CustomTypography>
            <CustomTypography variant="body2">
              {orderProcess.description}
            </CustomTypography>
          </Box>
          <Box flex={0} ml={1}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <img width={'20px'} src={orderProcess.icon} alt="" />
              <CustomTypography variant="body2">
                {toCurrencyFormat(orderProcess.price)}
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      ))}
      <Divider variant="middle"></Divider>
    </Grid>
  );
};
