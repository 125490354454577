import { ArrowBack, ShoppingCartOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Container, Toolbar } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Branch } from 'types/Branch';
import routesConstants from 'utils/routesConstants';
import { isBranchOpen } from 'utils/store';

type backButtonProps = {
  toggleOpenCart?: () => void;
  schedule?: any;
  branchId?: string;
  branch: Branch;
};

export const BackButton: React.FC<backButtonProps> = ({
  toggleOpenCart,
  schedule,
  branchId,
  branch,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    // TODO: Do this handling outside the BackButton component to make it more generic
    if (location.pathname.includes('/product')) {
      navigate(routesConstants.branch(branchId));
    } else {
      navigate(routesConstants.home);
    }
  };

  return (
    <>
      <AppBar
        id="app-bar"
        position="sticky"
        sx={{
          transition: 'all 0.3s ease-in-out',
          top: 0,
          zIndex: 2,
          backgroundColor: '#FFFFFF',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Button
                color="primary"
                aria-label="back"
                onClick={handleBack}
                startIcon={<ArrowBack />}
              >
                Atrás
              </Button>
            </Box>

            {toggleOpenCart && (
              <Button
                sx={{ display: { xs: 'flex', sm: 'flex' } }}
                variant="contained"
                onClick={toggleOpenCart}
                startIcon={<ShoppingCartOutlined />}
                disabled={!isBranchOpen(schedule, branch.timezone)}
              >
                Ver carrito
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
